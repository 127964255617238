export const EXTERNAL_LINKS = {
    ALLEGRO_ALL_AUCTIONS: "https://allegro.pl/uzytkownik/SalonUnikatow",
    ALLEGRO_FIGURINES: "https://allegro.pl/uzytkownik/SalonUnikatow/porcelana-figurki-26191",
    ALLERGO_ROSENTHAL: "https://allegro.pl/uzytkownik/SalonUnikatow/porcelana-rosenthal-26171",
    ALLEGRO_CLOCKS_AND_WATCHES: "https://allegro.pl/uzytkownik/SalonUnikatow/design-i-antyki-zegary-zegarki-26072",
    ALLEGRO_MEISSEN: "https://allegro.pl/uzytkownik/SalonUnikatow/porcelana-misnia-26165",
    ALLEGRO_CUPS: "https://allegro.pl/uzytkownik/SalonUnikatow?tags%5B%5D=SHOP-TAG-112777310-493eab2f-e29a-4e75-ab91-343423206c63",
    ALLEGRO_COMMENTS: "https://allegro.pl/uzytkownik/SalonUnikatow/oceny?page=1",
    ALLEGRO_HOME: "https://allegro.pl/",
    INSTAGRAM: "https://www.instagram.com/salon_unikatow/",
    WEB3FORMS_PRIVACY_POLICY: "https://web3forms.com/privacy",
    FACEBOOK: "https://www.facebook.com/profile.php?id=61572886640141"
}